import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Alert, Button, Card, Empty, Flex, Modal, Row, Space, Typography, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { usePublishSurvey, useQuestions, useSurvey } from '../../api/Surveys';
import BackButton from '../../components/BackButton';
import Loading from '../../components/Loading';
import { QCPhase, SurveyStatus } from '../../constants';
import useMatomo from '../../hooks/useMatomo';
import useParams from '../../hooks/useParams';
import FilteringQuestionDrawer from './FilteringQuestionDrawer';
import OpenEndedQuestionPhase from './OpenEndedQuestionPhase';
import SurveyDebriefModal from './SurveyDebriefModal';

const { Title, Text } = Typography;
const { confirm } = Modal;

const StyledDiv = styled.div`
  padding: 0 24px 24px;
`;

const StyledCard = styled(Card)`
  .ant-card-body {
    padding: 16px 24px;
  }
`;

const StyledText = styled(Text)`
  flex: 1 1;
  font-size: 16px;
  margin-right: 24px;
`;

function ThemeEnginePortal() {
  const { surveyId } = useParams();
  const { trackPageView } = useMatomo();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const [selectedFilteringQuestion, setSelectedFilteringQuestion] = useState(null);
  const [selectedOpenEndedQuestion, setSelectedOpenEndedQuestion] = useState(null);
  const [refetchInterval, setRefetchInterval] = useState(false);

  const { data: questions, isLoading: questionsLoading } = useQuery(
    ['questions', { surveyId }],
    useQuestions(),
  );
  const { data: survey, isLoading: surveyLoading } = useQuery(
    ['survey', { surveyId }],
    useSurvey(),
    {
      refetchInterval,
      onSuccess: (data) => {
        if (!refetchInterval && data.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS) {
          setRefetchInterval(3000);
        } else if (
          refetchInterval &&
          data.data.survey_status !== SurveyStatus.PUBLISH_IN_PROGRESS
        ) {
          setRefetchInterval(false);
        }
      },
    },
  );

  const { mutate: publishSurvey, isLoading: publishSurveyLoading } =
    useMutation(usePublishSurvey());

  const handlePublishSurvey = (status) => {
    if (status === SurveyStatus.PUBLISH_IN_PROGRESS) {
      setRefetchInterval(3000);
    }
    publishSurvey(
      { surveyId, data: { survey_status: status } },
      {
        onSuccess: () => {
          queryClient.setQueryData(['survey', { surveyId }], {
            ...survey,
            data: { ...survey.data, survey_status: status },
          });
          message.success(
            status === SurveyStatus.UNPUBLISHED
              ? 'Survey unpublished'
              : 'Survey publish in progress',
          );
        },
        onError: () => {
          message.error('Something went wrong while updating survey status');
        },
      },
    );
  };

  const confirmPublishSurvey = () => {
    confirm({
      title: 'Ready to lock it in? ',
      content:
        'Once you confirm, we’ll crunch the data and surface key insights. Make sure your code frame is solid and your coding is complete!',
      okText: 'Confirm',
      okType: 'danger',
      onOk: () => handlePublishSurvey(SurveyStatus.PUBLISH_IN_PROGRESS),
    });
  };

  useEffect(() => {
    trackPageView('Theme Engine Portal');
  }, [trackPageView]);

  if (questionsLoading || surveyLoading) {
    return <Loading />;
  }

  return (
    <StyledDiv>
      <BackButton onClick={() => navigate('/')} text="Back to Survey List" />
      <Flex justify="space-between" align="baseline">
        <Title level={4}>{survey.data.name}</Title>
        <Space>
          {questions.data.length > 0 && (
            <>
              <Button onClick={() => navigate(`/surveys/${surveyId}`)}>View dashboard</Button>
              {survey.data.survey_status === SurveyStatus.PUBLISHED ? (
                <Button
                  onClick={() => handlePublishSurvey(SurveyStatus.UNPUBLISHED)}
                  loading={publishSurveyLoading}
                  danger
                >
                  Unpublish
                </Button>
              ) : (
                <Button
                  type="primary"
                  onClick={() => confirmPublishSurvey()}
                  loading={
                    publishSurveyLoading ||
                    survey.data.survey_status === SurveyStatus.PUBLISH_IN_PROGRESS
                  }
                  danger
                >
                  Analyze + Publish
                </Button>
              )}
            </>
          )}
        </Space>
      </Flex>
      {questions.data.length > 0 ? (
        <>
          <Flex vertical gap={8}>
            <Title level={5}>Open-ended questions</Title>
            {questions.data.some((q) => q.has_themes && q.qc_phase === QCPhase.PROCESSING) && (
              <Alert
                message="Survey Processing"
                description="Some of your uploaded data is currently being processed. You will be able to access the survey data from this link once processing is complete. We'll also send you an email notification as soon as your data is ready. Thank you for your patience."
                type="info"
                showIcon
              />
            )}
            {questions.data
              .filter((question) => question.has_themes)
              .map((question) => (
                <StyledCard
                  key={question.id}
                  hoverable={question.qc_phase !== QCPhase.PROCESSING}
                  onClick={
                    question.qc_phase !== QCPhase.PROCESSING
                      ? () => setSelectedOpenEndedQuestion(question)
                      : null
                  }
                >
                  <Row justify="space-between" wrap={false}>
                    <StyledText ellipsis={{ tooltip: question.text }}>{question.text}</StyledText>
                    <OpenEndedQuestionPhase currentPhase={question.qc_phase} />
                  </Row>
                </StyledCard>
              ))}
          </Flex>
          <Flex vertical gap={8}>
            <Title level={5}>Filtering questions</Title>
            {questions.data
              .filter((question) => question.is_filterable)
              .map((question) => (
                <StyledCard
                  hoverable
                  key={question.id}
                  onClick={() => setSelectedFilteringQuestion(question)}
                >
                  <StyledText>{question.text}</StyledText>
                </StyledCard>
              ))}
          </Flex>
          <FilteringQuestionDrawer
            question={selectedFilteringQuestion}
            setSelectedFilteringQuestion={setSelectedFilteringQuestion}
            surveyId={surveyId}
          />
          <SurveyDebriefModal
            question={selectedOpenEndedQuestion}
            closeModal={() => setSelectedOpenEndedQuestion(null)}
            survey={survey}
          />
        </>
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description="Data is currently being processed. It should be ready in a few minutes. Thank you for your patience."
        />
      )}
    </StyledDiv>
  );
}

export default ThemeEnginePortal;
